import { VApp } from 'vuetify/lib/components/VApp';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VApp,
    [_vm.appBar ? _c("pages-core-app-bar") : _vm._e(), _c("pages-core-view")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }